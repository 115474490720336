import React from 'react';
import { RouteComponentProps } from 'react-router';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { rules, urls } from '@/constants';
import { translations } from '@/locale';
import { UserRole, WorkspaceDetails } from '@/domains';
import { deleteWorkspaceById, getWorkspaceDetails } from '@/services/api/workspace';

import Typography from '@/components/Typography';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import LoadData from '@/components/LoadData';
import If from '@/components/If';
import Loading from '@/components/Loading';
import Tab from '@/components/TabbedContainer/Tab';
import TabbedContainer from '@/components/TabbedContainer';
import Optional from '@/components/Optional';
import HasRole from '@/components/HasRole';
import ConfirmationModal from '@/components/Modal/ConfirmationModal';
import Modal from '@/components/Modal';

import BookingsTab from './BookingsTab';
import CalendarTab from './CalendarTab';
import PromoCodesTab from './PromoCodesTab';
import AboutTab from './AboutTab';
import TroubleshootingTab from './TroubleshootingTab';
import UserManagementTab from './UserManagementTab';
import AnalyticsTab from './AnalyticsTab';
import { SessionConsumer } from '@/components/Context/Session';

const pageTranslation = translations.pages.workspaces.details;

export interface Props {}

const WorkspaceDetailsPage: React.FC<Props & WrappedComponentProps & RouteComponentProps<{ id: string }>> = ({
  intl,
  match,
  history
}) => {
  const workspaceId = match.params.id;
  const editLocation = (locationId: string, value: WorkspaceDetails) =>
    history.push(urls.locations.edit.get(locationId), value);

  return (
    <SessionConsumer>
      {({ me }) => (
        <LoadData load={() => getWorkspaceDetails(workspaceId)}>
          {({ value: workspace, loading }) => (
            <If
              condition={loading}
              then={() => (
                <Loading visible={loading} center className="h-full">
                  <Loading.Indicator size={60} borderWidth={4} color="#F72431" />
                </Loading>
              )}
              else={() => (
                <div className="m-8">
                  <div className="flex justify-between mb-8">
                    <div>
                      <Typography is="h1" type="header" className="text-gray-darkest font-bold">
                        {workspace.name}
                      </Typography>

                      <Optional
                        of={workspace.deepLinkUrl}
                        then={(deepLinkUrl) => (
                          <a
                            href={deepLinkUrl}
                            className="mt-1 underline text-gray-dark"
                            target="_blank"
                            rel="noopener noreferrer"
                          >{`[ ${deepLinkUrl} ]`}</a>
                        )}
                      />
                    </div>

                    <div>
                      <HasRole to={rules.WORKSPACE_DELETE}>
                        <Modal
                          modal={ConfirmationModal}
                          message={intl.formatMessage({ id: translations.pages.workspaces.delete.message })}
                          confirmMessage={intl.formatMessage({ id: translations.pages.workspaces.delete.confirm })}
                          cancelMessage={intl.formatMessage({ id: translations.pages.workspaces.delete.cancel })}
                          onConfirm={() =>
                            deleteWorkspaceById(workspaceId).then(() => history.push(urls.locations.list))
                          }
                        >
                          {({ open }) => (
                            <Button appearance="ghost" onClick={open} className="mr-2">
                              <Icon type="close" className="text-base leading-none pr-2" />

                              <FormattedMessage id={pageTranslation.delete} />
                            </Button>
                          )}
                        </Modal>
                      </HasRole>

                      <Button appearance="red" onClick={() => editLocation(workspace.id, workspace)}>
                        <Icon type="edit" className="text-base leading-none pr-2" />

                        <FormattedMessage id={pageTranslation.editNooka} />
                      </Button>
                    </div>
                  </div>

                  <TabbedContainer resourceId={workspaceId}>
                    <Tab title={intl.formatMessage({ id: pageTranslation.tabs.about.title })} to={urls.locations.about}>
                      <AboutTab workspace={workspace} />
                    </Tab>

                    <Tab
                      title={intl.formatMessage({ id: pageTranslation.tabs.bookings.title })}
                      to={urls.locations.bookings}
                    >
                      <BookingsTab workspace={workspace} />
                    </Tab>

                    <Tab
                      title={intl.formatMessage({ id: pageTranslation.tabs.calendar.title })}
                      to={urls.locations.calendar}
                    >
                      <CalendarTab workspace={workspace} />
                    </Tab>

                    <Tab
                      title={intl.formatMessage({ id: pageTranslation.tabs.promoCodes.title })}
                      to={urls.locations.promoCodes}
                    >
                      <PromoCodesTab workspace={workspace} />
                    </Tab>

                    {me.role === UserRole.SuperAdmin && (
                      <Tab
                        title={intl.formatMessage({ id: pageTranslation.tabs.userManagement.title })}
                        to={urls.locations.userManagement}
                      >
                        <UserManagementTab workspace={workspace} />
                      </Tab>
                    )}

                    <Tab
                      title={intl.formatMessage({ id: pageTranslation.tabs.troubleshooting.title })}
                      to={urls.locations.troubleshooting}
                    >
                      <TroubleshootingTab workspace={workspace} />
                    </Tab>
                  </TabbedContainer>
                </div>
              )}
            />
          )}
        </LoadData>
      )}
    </SessionConsumer>
  );
};

export default injectIntl(WorkspaceDetailsPage);
