import { rules, urls } from '@/constants';
import { UserRole } from '@/domains';

const lessorRules = [
  rules.MAIN_LOCATIONS_VIEW,
  rules.MAIN_PROMOTIONS_VIEW,
  rules.MAIN_METRICS_VIEW,
  rules.LESSOR_METRICS_VIEW,
  rules.MAP_STATUS_VIEW,
  rules.MONITORING_VIEW,
  rules.USERS_PERSONAL_INFORMATION
];
const AdminRules = [
  rules.MAIN_LOCATIONS_VIEW,
  rules.MAIN_PROMOTIONS_VIEW,
  rules.MAIN_METRICS_VIEW,
  rules.ADMIN_METRICS_VIEW,
  rules.WORKSPACE_CREATE,
  rules.WORKSPACE_DELETE,
  rules.MAIN_BOOKINGS_VIEW,
  rules.MAIN_PURCHASED_PACKAGES_VIEW,
  rules.MAIN_STATUS_VIEW,
  rules.MAIN_FEATURES_VIEW,
  rules.MAIN_TROUBLESHOOTING_VIEW,
  rules.MAIN_TESTING_VIEW,
  rules.MAIN_TESTING_REPORTS_VIEW,
  rules.MAP_STATUS_VIEW,
  rules.MONITORING_VIEW,
  rules.USERS_PERSONAL_INFORMATION
];
const businessRules = [
  rules.MAIN_LOCATIONS_VIEW,
  rules.MAIN_PROMOTIONS_VIEW,
  rules.MAIN_METRICS_VIEW,
  rules.LESSOR_METRICS_VIEW,
  rules.MAP_STATUS_VIEW,
  rules.MONITORING_VIEW,
  rules.MAIN_BOOKINGS_VIEW,
  rules.USERS_PERSONAL_INFORMATION
];
const superAdminRules = [
  rules.MAIN_LOCATIONS_VIEW,
  rules.MAIN_PROMOTIONS_VIEW,
  rules.MAIN_METRICS_VIEW,
  rules.SUPER_ADMIN_METRICS_VIEW,
  rules.MAIN_USERS_VIEW,
  rules.WORKSPACE_CREATE,
  rules.WORKSPACE_DELETE,
  rules.MAIN_BOOKINGS_VIEW,
  rules.MAIN_PURCHASED_PACKAGES_VIEW,
  rules.MAIN_STATUS_VIEW,
  rules.MAIN_FEATURES_VIEW,
  rules.MAIN_TROUBLESHOOTING_VIEW,
  rules.MAIN_TESTING_VIEW,
  rules.MAIN_TESTING_REPORTS_VIEW,
  rules.MAP_STATUS_VIEW,
  rules.MONITORING_VIEW
];

const permissionMap = {
  [UserRole.IndividualLessor]: lessorRules,
  [UserRole.Business]: businessRules,
  [UserRole.SuperAdmin]: superAdminRules,
  [UserRole.Admin]: AdminRules
};

export const getDefaultUrl = (role: UserRole) => {
  switch (role) {
    case UserRole.IndividualLessor:
      return urls.locations.list;
    case UserRole.Business:
      return urls.locations.list;
    case UserRole.Admin:
      return urls.dashboard;
    case UserRole.SuperAdmin:
      return urls.dashboard;
    default:
      return urls.home;
  }
};

export const hasRole = (role: UserRole, rule: string) => {
  if (!role) {
    return false;
  }

  const permission = permissionMap[role];

  if (!permission) {
    return false;
  }

  if (permission.indexOf(rule) !== -1) {
    return true;
  }

  return false;
};
