import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

import { translations } from '@/locale';
import { createPromoCode } from '@/services/api/promo-code';
import { PromoCodeCreationForm, UserRole } from '@/domains';
import { promoCodeCreateOrEditSchema } from '@/validation/schema';

import { InstanceProps } from '@/components/Modal';
import Card from '@/components/Card';
import ModalHeader from '@/components/Modal/ModalHeader';
import Typography from '@/components/Typography';
import Grid from '@/components/Grid';
import PromoCodeForm from '@/components/Form/PromoCodeForm';
import Form from '@/components/Form';
import Button from '@/components/Button';
import { SessionConsumer } from '@/components/Context/Session';

export interface Props extends InstanceProps {
  reloadPromoCodeList: () => any;
}

const text = translations.pages.promotions.create;

const AddPromoCodeModal: React.FC<Props> = ({ reloadPromoCodeList, close }) => {
  return (
    <SessionConsumer>
      {({ me }) => (
        <Card className="w-[450px] p-6 mb-10">
          <Grid.Row>
            <Grid.Column className="w-full">
              <ModalHeader close={close} className="mb-8">
                <Typography is="span" type="small-header" className="font-bold">
                  <FormattedMessage id={text.title} />
                </Typography>
              </ModalHeader>
              <Form
                id="add-promo-code"
                schema={promoCodeCreateOrEditSchema as yup.ObjectSchema<PromoCodeCreationForm>}
                initialValues={{
                  userId: me.role !== UserRole.SuperAdmin && me.role !== UserRole.Admin ? me.id : undefined
                }}
                subscription={{ dirty: true, pristine: true, submitting: true, submitError: true, values: true }}
                onSubmit={(values) =>
                  createPromoCode(values).then(() => {
                    reloadPromoCodeList();
                    close();
                  })
                }
              >
                {(renderProps, id) => {
                  const { submitting, dirty, pristine } = renderProps;

                  return (
                    <React.Fragment>
                      <PromoCodeForm id={id} scope="create" formProps={renderProps} me={me} />

                      <Button
                        type="submit"
                        loading={submitting}
                        disabled={!dirty || pristine}
                        form={id}
                        appearance="red"
                        block
                      >
                        <FormattedMessage id={text.save} />
                      </Button>
                    </React.Fragment>
                  );
                }}
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Card>
      )}
    </SessionConsumer>
  );
};

export default AddPromoCodeModal;
