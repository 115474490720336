import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field } from 'react-final-form';

import { IotProvider, UserRole, WorkspaceFeature } from '@/domains';
import { translations } from '@/locale';
import { getWorkspaceFeatures } from '@/services/api/workspace-features';

import Typography from '@/components/Typography';
import { TextInputWithValidation } from '@/components/Input/TextInput';
import { TextAreaInputWithValidation } from '@/components/Input/TextAreaInput';
import Spacer from '@/components/Spacer';
import Form, { RenderProps as FormRenderProps } from '@/components/Form';
import { ImageGalleryUploadWithValidation } from '@/components/ImageUploadGallery';
import { CountrySelectWithValidation } from '@/components/Select/CountrySelect';
import Grid from '@/components/Grid';
import { CurrencySelectWithValidation } from '@/components/Select/CurrencySelect';
import { IotProviderSelectWithValidation } from '@/components/Select/IotProviderSelect';
import { DateInputWithValidation } from '@/components/Input/DateInput';
import { SubmitError } from '@/components/Error';
import { UsersSelectSelectWithValidation } from '@/components/Select/UsersSelect';
import { SessionConsumer } from '@/components/Context/Session';
import ToggleSwitch from '@/components/ToggleSwitch';
import LoadData from '@/components/LoadData';
import If from '@/components/If';
import Loading from '@/components/Loading';
import Card from '@/components/Card';
import WorkspaceFeatureSelect from '@/components/WorkspaceFeatureSelect';
import { wifiBandSelectWithValidation } from '@/components/Select/WifiBandSelect';
import { QrAccessCodeTypeSelectWithValidation } from '@/components/Select/QrAccessCodeTypeSelect';
import { IotProviderVersionSelectWithValidation } from '@/components/Select/IotProviderVersionSelect/IotProviderVersionSelect';
import BookingMinimTimeIntervalSelect from '@/components/Select/BookingMinimTimeIntervalSelect/BookingMinimTimeIntervalSelect';

export interface Props {
  id: string;
  text: { nookaInfo: string; iot: string; listing: string; images: string; features: string; wifi: string } & {
    [k: string]: any;
  };
  formProps: FormRenderProps<any>;
  scope: 'create' | 'edit';
  setChangePassword?: (value) => void;
  nookaLive: boolean;
}

const WorkspaceForm: React.FC<Props> = ({
  id,
  text,
  formProps: { submitting, submitError, form, values },
  scope,
  setChangePassword,
  nookaLive
}) => {
  const intl = useIntl();
  const [nookaLiveSelected, setNookaLiveSelected] = React.useState(nookaLive);

  const getSelectedFeature = (featureSet: WorkspaceFeature[], featureIds: string[] = []) =>
    featureSet.find((feature) => featureIds.includes(feature.id));

  const setFeatureId = (featureId: string, featureSet: WorkspaceFeature[]) => {
    const newFeatureIds = values.featureIds
      ? values.featureIds.filter((featureId) => featureSet.findIndex((feature) => feature.id === featureId) === -1)
      : [];

    if (featureId) {
      newFeatureIds.push(featureId);
    }

    form.change('featureIds', newFeatureIds);
  };

  return (
    <SessionConsumer>
      {({ me }) => {
        const isFieldDisabled =
          scope === 'edit' && (me!.role === UserRole.IndividualLessor || me!.role === UserRole.Business);
        return (
          <React.Fragment>
            <Grid.Row>
              <Grid.Column padded>
                <Typography is="h2" type="small-header" className="font-bold text-gray-darkest">
                  <FormattedMessage id={text.nookaInfo} />
                </Typography>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column sm={6} padded>
                <Form.Field
                  is={TextInputWithValidation}
                  type="text"
                  id={`${id}-name`}
                  name="name"
                  label={intl.formatMessage({ id: translations.inputs.fields.name.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.fields.name.placeholder })}
                  readOnly={submitting}
                  disabled={isFieldDisabled}
                  required
                />

                <Spacer xs={3} />

                <Form.Field
                  is={TextInputWithValidation}
                  type="text"
                  id={`${id}-address`}
                  name="address"
                  label={intl.formatMessage({ id: translations.inputs.fields.address.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.fields.address.placeholder })}
                  readOnly={submitting}
                  disabled={isFieldDisabled}
                  required
                />
              </Grid.Column>

              <Grid.Column sm={6} padded>
                <Form.Field
                  inputClassName="resize-none h-32"
                  is={TextAreaInputWithValidation}
                  type="text"
                  id={`${id}-description`}
                  name="description"
                  label={intl.formatMessage({ id: translations.inputs.fields.description.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.fields.description.placeholder })}
                  readOnly={submitting}
                  required
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column sm={3} padded>
                <Form.Field
                  is={TextInputWithValidation}
                  type="number"
                  id={`${id}-latitude`}
                  name="latitude"
                  label={intl.formatMessage({ id: translations.inputs.fields.latitude.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.fields.latitude.placeholder })}
                  readOnly={submitting}
                  disabled={isFieldDisabled}
                  required
                />
              </Grid.Column>

              <Grid.Column sm={3} padded>
                <Form.Field
                  is={TextInputWithValidation}
                  type="number"
                  id={`${id}-longitude`}
                  name="longitude"
                  label={intl.formatMessage({ id: translations.inputs.fields.longitude.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.fields.longitude.placeholder })}
                  readOnly={submitting}
                  disabled={isFieldDisabled}
                  required
                />
              </Grid.Column>

              <Grid.Column sm={3} padded>
                <Form.Field
                  is={CountrySelectWithValidation}
                  id={`${id}-country`}
                  name="country"
                  label={intl.formatMessage({ id: translations.inputs.fields.country.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.fields.country.placeholder })}
                  readOnly={submitting}
                  searchable
                  disabled={isFieldDisabled}
                  required
                />
              </Grid.Column>

              <Grid.Column sm={3} padded>
                <Form.Field
                  is={TextInputWithValidation}
                  type="text"
                  id={`${id}-city`}
                  name="city"
                  label={intl.formatMessage({ id: translations.inputs.fields.city.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.fields.city.placeholder })}
                  readOnly={submitting}
                  disabled={isFieldDisabled}
                  required
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column sm={3} padded>
                <Form.Field
                  is={DateInputWithValidation}
                  id={`${id}-installation-date`}
                  name="installationDate"
                  label={intl.formatMessage({ id: translations.inputs.fields.installationDate.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.fields.date.placeholder })}
                  readOnly={submitting}
                  disabled={isFieldDisabled}
                />
              </Grid.Column>

              <Grid.Column sm={3} padded>
                <Form.Field
                  is={DateInputWithValidation}
                  id={`${id}-construction-date`}
                  name="constructionDate"
                  label={intl.formatMessage({ id: translations.inputs.fields.constructionDate.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.fields.date.placeholder })}
                  readOnly={submitting}
                  disabled={isFieldDisabled}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column sm={3} padded>
                <Form.Field
                  is={UsersSelectSelectWithValidation}
                  id={`${id}-owners`}
                  name="ownerIds"
                  label={intl.formatMessage({ id: translations.inputs.fields.assign.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.fields.assign.placeholder })}
                  readOnly={submitting}
                  searchable
                  editable
                  disabled={isFieldDisabled}
                  required
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column padded>
                <Typography is="h2" type="small-header" className="font-bold text-gray-darkest">
                  <FormattedMessage id={text.listing} />
                </Typography>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column sm={3} className="flex items-center" padded>
                <Form.Field
                  is={ToggleSwitch}
                  id={`${id}-private`}
                  type="checkbox"
                  name="private"
                  label={false}
                  checked={values.private}
                  onChange={() => {
                    form.change('private', !values.private);
                    form.change('price', undefined);
                    form.change('currency', undefined);
                    form.change('currency-fee', undefined);
                  }}
                  disabled={me.role !== UserRole.IndividualLessor ? false : true}
                >
                  <Typography is="div" type="regular" className="font-bold text-gray-darkest">
                    <FormattedMessage id={translations.inputs.toggles.workspacePrivate} />
                  </Typography>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column sm={3} padded>
                <Form.Field
                  is={TextInputWithValidation}
                  type="number"
                  id={`${id}-price`}
                  name="price"
                  label={intl.formatMessage({ id: translations.inputs.fields.price.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.fields.price.placeholder })}
                  readOnly={submitting}
                  disabled={values.private === false ? (me.role !== UserRole.IndividualLessor ? false : true) : true}
                  required={!values.private}
                />
              </Grid.Column>

              <Grid.Column sm={3} padded>
                <Form.Field
                  is={CurrencySelectWithValidation}
                  id={`${id}-currency`}
                  name="currency"
                  label={intl.formatMessage({ id: translations.inputs.fields.currency.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.fields.currency.placeholder })}
                  readOnly={submitting}
                  disabled={values.private}
                  required={!values.private}
                />
              </Grid.Column>
            </Grid.Row>

            {!values.private && (
              <>
                <Grid.Row>
                  <Grid.Column sm={3} className="flex items-center" padded>
                    <Form.Field
                      is={ToggleSwitch}
                      id={`${id}-cleaning-deposit`}
                      type="checkbox"
                      name="cleaningDeposit"
                      label={false}
                      checked={values.cleaningDeposit ?? false}
                      onChange={() => {
                        form.change('cleaningDeposit', !values.cleaningDeposit);
                        form.change('cleaningDepositFee', undefined);
                      }}
                      disabled={me.role === UserRole.IndividualLessor ? true : false}
                    >
                      <Typography is="div" type="regular" className="font-bold text-gray-darkest">
                        <FormattedMessage id={translations.inputs.toggles.cleaningDeposit} />
                      </Typography>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column sm={3} padded>
                    <Form.Field
                      is={TextInputWithValidation}
                      type="number"
                      id={`${id}-cleaning-deposit-fee`}
                      name="cleaningDepositFee"
                      label={intl.formatMessage({ id: translations.inputs.fields.price.label })}
                      placeholder={intl.formatMessage({ id: translations.inputs.fields.price.placeholder })}
                      readOnly={submitting}
                      disabled={!values.cleaningDeposit}
                      required={values.cleaningDeposit}
                    />
                  </Grid.Column>
                </Grid.Row>
              </>
            )}

            <Grid.Row>
              <Grid.Column padded>
                <Typography is="h2" type="small-header" className="font-bold text-gray-darkest">
                  <FormattedMessage id={text.bookingEfficiency} />
                </Typography>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Row padded>
                <Form.Field
                  is={BookingMinimTimeIntervalSelect}
                  id={`${id}-minimum-booking-period-in-minutes`}
                  name="minimumBookingPeriodInMinutes"
                  label={intl.formatMessage({ id: translations.bookingMinimTimeInterval.labelBookingTime })}
                  placeholder={intl.formatMessage(
                    {
                      id: translations.bookingMinimTimeInterval.listItem
                    },
                    { value: values.minimumBookingPeriodInMinutes }
                  )}
                  readOnly={submitting}
                  disabled={isFieldDisabled}
                />

                <Form.Field
                  is={BookingMinimTimeIntervalSelect}
                  id={`${id}-booking-time-increment-step-in-minutes`}
                  name="bookingTimeIncrementStepInMinutes"
                  label={intl.formatMessage({ id: translations.bookingMinimTimeInterval.labelBookingTimeStep })}
                  placeholder={intl.formatMessage(
                    {
                      id: translations.bookingMinimTimeInterval.listItem
                    },
                    { value: values.bookingMinimTimeInterval ?? 30 }
                  )}
                  readOnly={submitting}
                  disabled={isFieldDisabled}
                  className="ml-5"
                />
              </Grid.Row>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column padded>
                <Typography is="h2" type="small-header" className="font-bold text-gray-darkest">
                  <FormattedMessage id={text.iot} />
                </Typography>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column sm={3} className="flex items-center" padded>
                <Form.Field
                  is={ToggleSwitch}
                  id={`${id}-iot-enabled`}
                  name="iotEnabled"
                  type="checkbox"
                  label={false}
                  checked={values.iotEnabled}
                  onChange={() => {
                    form.change('iotEnabled', !values.iotEnabled);
                    form.change('provider', undefined);
                    form.change('providerId', undefined);
                    form.change('routerIp', undefined);
                  }}
                  disabled={me.role === UserRole.IndividualLessor}
                >
                  <Typography is="div" type="regular" className="text-gray-darkest">
                    <FormattedMessage id={translations.inputs.fields.iotEnabled.label} />
                  </Typography>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column sm={3} padded>
                <Form.Field
                  is={IotProviderSelectWithValidation}
                  id={`${id}-iot-integrator`}
                  name="provider"
                  label={intl.formatMessage({ id: translations.inputs.fields.iotIntegrator.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.fields.iotIntegrator.placeholder })}
                  readOnly={submitting}
                  disabled={isFieldDisabled || !values.iotEnabled}
                  required={values.iotEnabled}
                  onChange={() =>
                    form.getFieldState('provider').value === IotProvider.NookaLive
                      ? setNookaLiveSelected(true)
                      : setNookaLiveSelected(false)
                  }
                />
              </Grid.Column>
              <Grid.Column sm={3} padded>
                <Form.Field
                  is={TextInputWithValidation}
                  type="text"
                  id={`${id}-identifier`}
                  name="providerId"
                  label={intl.formatMessage({ id: translations.inputs.fields.identifier.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.fields.identifier.placeholder })}
                  readOnly={submitting}
                  disabled={isFieldDisabled || !values.iotEnabled}
                  required={values.iotEnabled}
                />
              </Grid.Column>
              {!isFieldDisabled && (
                <Grid.Column sm={3} padded>
                  <Form.Field
                    is={TextInputWithValidation}
                    type="text"
                    id={`${id}-router-ip`}
                    name="routerIp"
                    label={intl.formatMessage({ id: translations.inputs.fields.routerIp.label })}
                    placeholder={intl.formatMessage({ id: translations.inputs.fields.routerIp.placeholder })}
                    readOnly={submitting}
                    disabled={!values.iotEnabled}
                  />
                </Grid.Column>
              )}
              {!isFieldDisabled && nookaLiveSelected && (
                <Grid.Column sm={3} padded>
                  <Form.Field
                    is={IotProviderVersionSelectWithValidation}
                    id={`${id}-iot-integrator-version`}
                    name="versionIOT"
                    label={intl.formatMessage({ id: translations.inputs.fields.boardVersion.label })}
                    placeholder={intl.formatMessage({ id: translations.inputs.fields.boardVersion.placeholder })}
                    readOnly={submitting}
                    disabled={isFieldDisabled || !values.iotEnabled}
                    required={nookaLiveSelected}
                  />
                </Grid.Column>
              )}
            </Grid.Row>

            <Grid.Row className="flex items-center">
              <Grid.Column sm={3} padded>
                <Form.Field
                  is={ToggleSwitch}
                  id={`${id}-has-qr-access`}
                  type="checkbox"
                  name="hasQrAccess"
                  label={false}
                  checked={values.private}
                  disabled={isFieldDisabled}
                >
                  <Typography is="div" type="regular" className="text-gray-darkest">
                    <FormattedMessage id={translations.inputs.toggles.accessUsingQrCode} />
                  </Typography>
                </Form.Field>
              </Grid.Column>

              <Grid.Column sm={3} padded>
                <Form.Field
                  is={QrAccessCodeTypeSelectWithValidation}
                  id={`${id}-preferred-qr-access-code`}
                  name="preferredQrAccessCode"
                  label={intl.formatMessage({ id: translations.preferredQrAccessCode.label })}
                  placeholder={intl.formatMessage({ id: translations.preferredQrAccessCode.placeholder })}
                  readOnly={submitting}
                  disabled={isFieldDisabled ? isFieldDisabled : !values.hasQrAccess}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column sm={6} padded>
                <Form.Field
                  is={TextAreaInputWithValidation}
                  id={`${id}-access-message`}
                  name="accessMessage"
                  label={intl.formatMessage({ id: translations.accessMessage.label })}
                  placeholder={intl.formatMessage({ id: translations.accessMessage.placeholder })}
                  readOnly={submitting}
                  inputClassName="resize-none h-20"
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column padded>
                <Typography is="h2" type="small-header" className="font-bold text-gray-darkest">
                  <FormattedMessage id={text.wifi} />
                </Typography>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column padded>
                <FormattedMessage id={translations.wifiDescription} />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column sm={3} padded>
                <Form.Field
                  is={TextInputWithValidation}
                  type="text"
                  id={`${id}-wifi-ssid-0`}
                  name="mainWifiSSID"
                  label={intl.formatMessage({ id: translations.inputs.fields.mainWifiSSID.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.fields.mainWifiSSID.placeholder })}
                  readOnly={submitting}
                  disabled={isFieldDisabled}
                  required
                />
              </Grid.Column>

              <Grid.Column sm={3} padded>
                <Form.Field
                  is={TextInputWithValidation}
                  type="text"
                  id={`${id}-wifi-password-0`}
                  name="mainWifiPassword"
                  label={intl.formatMessage({ id: translations.inputs.fields.wifiPassword.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.fields.wifiPassword.placeholder })}
                  readOnly={submitting}
                  disabled={isFieldDisabled}
                  required
                />
              </Grid.Column>

              <Grid.Column sm={3} padded>
                <Form.Field
                  is={wifiBandSelectWithValidation}
                  id={`${id}-wifi-band-0`}
                  name="mainWifiBand"
                  label={intl.formatMessage({ id: translations.wifiBand.label })}
                  placeholder={intl.formatMessage({ id: translations.wifiBand.placeholder })}
                  readOnly={submitting}
                  disabled={isFieldDisabled}
                  required
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column sm={3} padded>
                <Form.Field
                  is={TextInputWithValidation}
                  type="text"
                  id={`${id}-wifi-ssid-1`}
                  name={`additionalWifiSSID`}
                  label={intl.formatMessage({ id: translations.inputs.fields.addidtionalWifiSSID.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.fields.addidtionalWifiSSID.placeholder })}
                  readOnly={submitting}
                  disabled={isFieldDisabled}
                />
              </Grid.Column>

              <Grid.Column sm={3} padded>
                <Form.Field
                  is={TextInputWithValidation}
                  type="text"
                  id={`${id}-wifi-password-1`}
                  name={`additionalWifiPassword`}
                  label={intl.formatMessage({ id: translations.inputs.fields.wifiPassword.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.fields.wifiPassword.placeholder })}
                  readOnly={submitting}
                  disabled={isFieldDisabled}
                />
              </Grid.Column>

              <Grid.Column sm={3} padded>
                <Form.Field
                  is={wifiBandSelectWithValidation}
                  id={`${id}-wifi-band-1`}
                  name="additionalWifiBand"
                  label={intl.formatMessage({ id: translations.wifiBand.label })}
                  placeholder={intl.formatMessage({ id: translations.wifiBand.placeholder })}
                  readOnly={submitting}
                  disabled={isFieldDisabled}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Form.Field
                  is={ToggleSwitch}
                  id={`${id}-change-wifi-password`}
                  name="changePassword"
                  type="checkbox"
                  className="ml-3 mt-3"
                  label={false}
                  readOnly={submitting}
                  disabled={isFieldDisabled}
                  onChange={() => {
                    setChangePassword(form.getFieldState('changePassword').dirty);
                  }}
                >
                  {intl.formatMessage({ id: translations.changePassword })}
                </Form.Field>
              </Grid.Column>
            </Grid.Row>

            <Spacer className="border-b border-solid border-gray-lighter my-7" />

            <Grid.Row>
              <Grid.Column padded>
                <Typography is="h2" type="small-header" className="text-gray-darkest font-bold">
                  <FormattedMessage id={text.images} />
                </Typography>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column sm={12} padded>
                <Form.Field
                  is={ImageGalleryUploadWithValidation}
                  name="images"
                  images={values.images}
                  setImages={(images) => form.change('images', images)}
                  onImageUploaded={(image) => {
                    form.change('images', [...values.images, ...image]);
                  }}
                />

                <SubmitError error={submitError} className="mt-8" />
              </Grid.Column>
            </Grid.Row>

            <Spacer className="border-b border-solid border-gray-lighter my-7" />

            <Grid.Row>
              <Grid.Column padded>
                <Typography is="h2" type="small-header" className="text-gray-darkest font-bold">
                  <FormattedMessage id={text.features} />
                </Typography>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column padded>
                <LoadData load={() => getWorkspaceFeatures()}>
                  {({ value: workspaceFeatureItems, loading }) => (
                    <If
                      condition={loading}
                      then={() => (
                        <Loading visible={loading} center className="h-full">
                          <Loading.Indicator size={60} borderWidth={4} color="#F72431" />
                        </Loading>
                      )}
                      else={() => (
                        <React.Fragment>
                          <Card className="flex flex-wrap">
                            {workspaceFeatureItems &&
                              workspaceFeatureItems.map(({ features }) => (
                                <WorkspaceFeatureSelect
                                  key={features[0].id}
                                  features={features}
                                  selectedFeature={getSelectedFeature(features, values.featureIds)}
                                  onChange={(value) => setFeatureId(value, features)}
                                  className="m-8"
                                />
                              ))}
                          </Card>

                          {/* This will update pristine / dirty props when we call form.change() */}
                          <Field name="featureIds">{() => null}</Field>
                        </React.Fragment>
                      )}
                    />
                  )}
                </LoadData>
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>
        );
      }}
    </SessionConsumer>
  );
};

export default WorkspaceForm;
